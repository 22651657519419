<template>
  <el-row v-if="isShowBiofuelThreeFields" :gutter="10">
    <el-col :sm="24" :md="8">
        <el-form-item
        prop="blendRatio"
        label="Blend Ratio (For MPA Reporting)"
        :rules="getRule"
      >
      <el-input-number style="width: 100%"  v-model="schedulFormData.blendRatio"  :min="0" :max="100"></el-input-number>
      </el-form-item>
    </el-col>
    <el-col :sm="24" :md="8">
      <el-form-item
        prop="biofuelGenCode"
        label="Biofuel Gen Code (For MPA Reporting)"
        :rules="getRule"
      >
        <!-- @change-visible="" -->
        <el-select
          v-model="schedulFormData.biofuelGenCode"
          style="width: 100%"
          placeholder="Select"
          @visible-change="getMpaBioFuelGenCodeOptions"

        >
          <el-option
            v-for="item in biofuelGenCodeOptions"
            :key="item.biofuelGenCode"
            :label="item.biofuelGenDescription"
            :value="item.biofuelGenCode"
          >
          </el-option>
        </el-select>
      </el-form-item>
    </el-col>
    <el-col :sm="24" :md="8">
      <el-form-item
      prop="biofuelISCC"
      label="Biofuel ISCC (For MPA Reporting)"
      :rules="getRule"
      >
      <!-- @change="handleChangeBiofuelISCC" -->
      <el-select
          v-model="schedulFormData.biofuelISCC"
          style="width: 100%"
          placeholder="Select"


        >
          <el-option
            v-for="item in YES_NO_OPTION_CHAR"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          >
          </el-option>
        </el-select>
      </el-form-item>
    </el-col>
    <el-col v-if="isShowBiofuelISCCValue" :sm="24" :md="8">
      <el-form-item
      prop="biofuelISCCValue"
      label="Biofuel ISCC Value (For MPA Reporting)"
      :rules="isShowBiofuelISCCValue? REQ_RULE : null"
      >
        <el-input-number v-model="schedulFormData.biofuelISCCValue" style="width: 100%" :precision="2" :min="0.00" :max="99.99"></el-input-number>

      </el-form-item>
    </el-col>
    <el-col v-if="isShowBiofuelISCCScheme" :sm="24" :md="8">
      <el-form-item
      prop="biofuelCertScheme"
      label="Biofuel Cert Scheme (For MPA Reporting)"
      :rules="isShowBiofuelISCCScheme? REQ_RULE : null"
      >
        <el-input v-model="schedulFormData.biofuelCertScheme"></el-input>

      </el-form-item>
    </el-col>
    <el-col v-if="isShowTypeOfFeedstock" :sm="24" :md="8">
      <el-form-item
      prop="typeOfFeedstock"
      label="Type Of Feedstock (For MPA Reporting)"
      :rules="isShowTypeOfFeedstock? REQ_RULE : null"
      >
        <el-input v-model="schedulFormData.typeOfFeedstock"></el-input>
      </el-form-item>
    </el-col>
  </el-row>
</template>

<script>
import { MPA_FUEL_BUNKER_TYPE_FLAG, YES_NO_OPTION_CHAR, REQ_RULE } from '@/constants.js'
import {mapState } from "vuex";
export default {
  name: 'FuelTypeRelatedInformation',
  props: {
    schedulFormData: Object,
  },
  data () {
    return {
      MPA_FUEL_BUNKER_TYPE_FLAG,
      YES_NO_OPTION_CHAR,
      REQ_RULE,
      biofuelGenCodeOptions:[],
     isShowTypeOfFeedstock:false,
     isShowBiofuelThreeFields:false,
    }
  },
mounted(){

  this.getMpaBioFuelGenCodeOptions()

},

computed:{
   ...mapState({
      mpaFuelTypeList: "mpaFuelTypeList",
    }),
  getRule(){
   return this.isShowBiofuelThreeFields
        ? this. REQ_RULE
        : null
  },
  isShowBiofuelISCCValue(){
    // return this.schedulFormData.biofuelISCC == MPA_FUEL_BUNKER_TYPE_FLAG['Y']
    /// just hide Biofuel ISCC value do not delete
    return false;
  },
  isShowBiofuelISCCScheme(){
    return this.schedulFormData.biofuelISCC == MPA_FUEL_BUNKER_TYPE_FLAG['N']
  }
},
 watch: {
    "schedulFormData.fuelTypeCode": {
      handler(value) {
          if (value) {
          const item = this.mpaFuelTypeList.filter(item=>item.value ==value)[0]
          if (item?.bunkerType.toUpperCase() == this.MPA_FUEL_BUNKER_TYPE_FLAG['BIOFUEL'] || (!item && this.schedulFormData.blendRatio && this.schedulFormData.biofuelGenCode && this.schedulFormData.biofuelISCC)){
            this.isShowBiofuelThreeFields = true
            this.isShowTypeOfFeedstock = value.toUpperCase() == this.MPA_FUEL_BUNKER_TYPE_FLAG['OTHERS'];
          }else{
            this.isShowBiofuelThreeFields= false
            this.resetBiofuelThreeFieldValue(true)
          }
        }
      },
      immediate: true,
    },
  },

methods: {
  getMpaBioFuelGenCodeOptions() {
    this.$request.get({
      url: this.$apis.mpaBiofuelGenCodes,
    }).then((res) => {
      if (res?.code === 1000) {
        this.biofuelGenCodeOptions = res?.data??[]
      }
    });
  },
  resetBiofuelThreeFieldValue(isClear){
    if (isClear) {
        this.schedulFormData.blendRatio = null
        this.schedulFormData.biofuelGenCode = null
        this.schedulFormData.biofuelISCC = null
        this.schedulFormData.biofuelISCCValue = null
        this.schedulFormData.biofuelCertScheme = null
        this.schedulFormData.typeOfFeedstock = null
    }
  },
}
}
</script>

<style lang="scss" scoped>
@media screen and (max-width: 599px) {


  .el-select-dropdown__item{
    font-size:12px;
    padding:0 10px ;
    height:30px;
    // line-height: 30px;

  }

}
@media screen and (max-width: 400px) {

  .el-select-dropdown__item{
    font-size:11px;
    padding:0 5px ;
  }

}

</style>
